(function() {
    'use strict';
    angular
        .module('tmgApp')
        .factory('ChargeTechnicien', ChargeTechnicien);

    ChargeTechnicien.$inject = ['$resource', 'DateUtils'];

    function ChargeTechnicien ($resource, DateUtils) {
        var resourceUrl =  'api/charge-technicien/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getByIntervention': {
                methods: 'GET',
                url: 'api/charge-technicien/intervention',
                params: {
                    interventionId: '@interventionId'
                },
                isArray: true,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                }
            },
            'regulChargeForActivity' : {
                method:'POST',
                url: 'api/charge-technicien/regulForActivity',
                hasBody:true,
                isArray: false,
                transformRequest: function (data) {
                    var copy = angular.copy(data);
                    return angular.toJson(copy);
                }
            },

        });

    }
})();
