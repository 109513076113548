(function() {
    'use strict';

    angular
        .module('tmgApp')
        .controller('CongesDialogController', CongesDialogController);

    CongesDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$uibModal' , 'entity', 'Conges', 'Technicien', 'Principal', '$state', 'Equipe'];

    function CongesDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $uibModal,entity, Conges, Technicien, Principal, $state, Equipe) {
        $(document).ready(function () {
            $('#field_technicien').select2();
            $('#field_technicien').on('select2:select', function (e) {
                vm.conges.technicien = $('#field_technicien :selected').val();
            });
        });
        var vm = this;

        vm.conges = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.deleteConge = deleteConge;
        vm.equipes = [];
        vm.isAdmin = false;
        vm.loadEquipes = loadEquipes;

        Principal.identity().then(function(account) {
            if (account.technicien) {
                vm.techFullDisplayname = account.technicien.displayname;
            }
            vm.isAdmin = account.authorities.indexOf("ROLE_ADMIN") === 1
        });

        loadTechniciens();
        firstLoadEquipes()

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.conges.id !== null) {
                Conges.update(vm.conges, onSaveSuccess, onSaveError);
            } else {
                Conges.save(vm.conges, onSaveSuccess, onSaveError);
            }
        }

        function firstLoadEquipes() {
            if (vm.conges.id) {
                loadEquipes();
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('tmgApp:congesUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.dateEnd = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function loadTechniciens(){
        	Technicien.technicienEnabled({ }, function onSuccess(data, headers) {
                vm.techniciens = data;
            }, function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function loadEquipes(){
            if(!vm.conges.date || !vm.conges.dateEnd || !vm.conges.technicien) return;

            Equipe.getByDatesAndTech({
                start: vm.conges.date.toISOString().split('T')[0],
                end: vm.conges.dateEnd.toISOString().split('T')[0],
                technicien: vm.conges.technicien
            },
            function onSuccess(data, headers) {
                vm.equipes = data;
            },
            function onError(error) {
                AlertService.error(error.data.message);
            });
        }

        function deleteConge(id) {
            $uibModal.open({
                templateUrl: 'app/entities/conges/conges-delete-dialog.html',
                controller: 'CongesDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['Conges', function(Conges) {
                        return Conges.get({id : id}).$promise;
                    }]
                }
            }).result.then(function() {
                $uibModalInstance.close(true);
                $state.reload();
            });
        }
    }
})();
